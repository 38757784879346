import { Link, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { FILTER_KEYS } from "../../enums/enums";

interface PaginationProps {
  numberOfPages: number;
  currentPageNumber: number;
  itemsPerPage: number;
  totalItemCount: number;
}

const Pagination = ({
  numberOfPages,
  currentPageNumber,
  itemsPerPage,
  totalItemCount,
}: PaginationProps) => {
  const [searchParams] = useSearchParams();

  const pageNumberItems = [...Array(numberOfPages)].map((_, pageNumber) => {
    pageNumber += 1;
    searchParams.set(FILTER_KEYS.PAGE, pageNumber.toString());
    const href = `?${searchParams.toString()}`;
    const isActive = pageNumber === currentPageNumber;
    return {
      pageNumber,
      isActive,
      href,
    };
  });

  const showingFrom = Math.min(
    (currentPageNumber - 1) * itemsPerPage + 1,
    totalItemCount,
  );
  const showingTo = Math.min(currentPageNumber * itemsPerPage, totalItemCount);
  return (
    <Wrapper>
      <div>{`Visar ${showingFrom} till ${showingTo} av totalt ${totalItemCount} patient${
        totalItemCount == 1 ? "" : "er"
      }`}</div>
      <Nav>
        {pageNumberItems.length > 1 &&
          pageNumberItems.map((item) => (
            <PageNumberLink
              key={item.pageNumber}
              to={item.href}
              $isActive={item.isActive}
            >
              {item.pageNumber}
            </PageNumberLink>
          ))}
      </Nav>
    </Wrapper>
  );
};

const PageNumberLink = styled(Link)<{ $isActive: boolean }>`
  border-radius: 50%;
  border: 1px solid ${(props) =>
    props.$isActive
      ? props.theme.pagination.active
      : props.theme.colors.primary};
  margin: 0 2px;
  text-decoration: none;
  color: ${(props) => props.theme.pagination.dark};
  background-color: ${(props) =>
    props.$isActive
      ? props.theme.pagination.active
      : props.theme.pagination.light};
  display: inline-block;
  line-height: 1.375rem;
  min-width: 2.5rem;
  text-align: center;
  padding: 0.5rem;


  :hover:not(.active) {
    :hover:not(.active) {
      border: 1px solid ${(props) => props.theme.pagination.dark};
      color: ${(props) =>
        props.$isActive
          ? props.theme.pagination.dark
          : props.theme.pagination.light};
      background-color: ${(props) =>
        props.$isActive
          ? props.theme.pagination.active
          : props.theme.pagination.dark};
    }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 12px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    height: 18px;
    filter: blur(9px);
    z-index: 0;
    background-color: #fff;
  }
`;

const Nav = styled.nav`
  display: block;
`;

export default Pagination;
